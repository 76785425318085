import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Template from "../layouts/base"
import img403 from "../assets/images/403.png"
import cloudsBottomLeft from "../assets/images/clouds-bottom-left.png"
import cloudsBottomRight from "../assets/images/clouds-bottom-right.png"
import withAuth from "../components/withAuthHoc"

const NotPermissionsPage = () => {
  const intl = useIntl()

  return (
    <Template>
      <div
        style={{
          textAlign: "center",
          position: "relative",
          height: "calc(100vh - 150px)",
        }}
      >
        <img alt="403" src={img403} height={370} />
        <div style={{ fontSize: "32px", color: "#000000" }}>
          <div>{intl.formatMessage({ id: "user-without-permissions" })}</div>
          <div>{intl.formatMessage({ id: "contact-with-admin" })}</div>
        </div>
        <div
          style={{
            display: "flex",
            position: "absolute",
            bottom: "0",
            width: "100%",
          }}
        >
          <div style={{ width: "100%", textAlign: "left", alignSelf: "end" }}>
            <img alt="cloudsBottomLeft" src={cloudsBottomLeft} height={300} />
          </div>
          <div style={{ width: "100%", textAlign: "right", alignSelf: "end" }}>
            <img alt="cloudsBottomRight" src={cloudsBottomRight} height={300} />
          </div>
        </div>
      </div>
    </Template>
  )
}

export default withAuth(NotPermissionsPage)
