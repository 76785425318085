import React from "react"
import { navigateToLoginPage, isLoggedIn } from "../services/auth"

export default function withAuthHoc(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        userIsLoggedIn: false,
      }
    }

    checkPublicPage() {
      return !!(
        this.props.pathContext &&
        this.props.pathContext.intl &&
        this.props.pathContext.intl.originalPath &&
        this.props.pathContext.intl.originalPath.includes("/public/")
      )
    }

    componentWillMount() {
      if (this.checkPublicPage()) {
        this.setState({ userIsLoggedIn: true })
        return
      }
      isLoggedIn()
        .then(_ => this.setState({ userIsLoggedIn: true }))
        .catch(() => {
          navigateToLoginPage()
        })
    }

    render() {
      return this.state.userIsLoggedIn ? <Component {...this.props} /> : null
    }
  }
}
